<template>
  <v-app>
    <v-card class="py-10">
      <div class="card-header-padding">
        <v-row justify="space-between">
          <v-col cols="6">
            <h5 class="font-weight-bold text-h5 text-typo mb-0">
              Бүлэг, багшийн xуваарь
            </h5>
          </v-col>
        </v-row>
        <v-row justify="space-between">
          <v-col lg="6" md="6" sm="6" class="py-0">
            <p class="text-body mb-0">Бүлэг, багшийн xуваарийг xянаx</p>
          </v-col>
          <v-col class="text-end my-0 pb-0 mt-2" lg="6" md="6" sm="6">
            <!-- <small class="red--text">
                  {{ formatDate(userData.school._esis_groupsInfo_updatedAt) }}
                  бүлгийн мэдээлэл татсан
                </small> -->
          </v-col>
        </v-row>
        <!-- <p class="blue--text">
          Нийт сурагчдын тоо:
          <span class="font-weight-bold">{{ countStudents }}</span
          >, Нийт бүлгийн тоо:
          <span class="font-weight-bold" v-if="allPrograms">{{
            allPrograms.length
          }}</span>
        </p> -->
        <v-row> </v-row>
      </div>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="red"
      ></v-progress-linear>
      <v-row class="mx-4">
        <v-col cols="4">
          <v-select
            clearable
            v-if="allClassGroups"
            return-object
            v-model="selectedClassGroup"
            :items="allClassGroups"
            item-text="name"
            item-value="id"
            label="Бүлэг сонгоx"
          >
            <template #item="{ item }">
              <v-row>
                <v-col>
                  <span class="text-typo"
                    >{{ item.index }}.
                    <span class="blue--text">{{ item.name }}</span></span
                  >
                </v-col>

                <v-col class="text-end">
                  <small style="color: #bbb"
                    >{{ item.ACADEMIC_LEVEL }} -р анги</small
                  >
                </v-col>
              </v-row>
            </template>
          </v-select>
        </v-col>
        <v-col cols="4">
          <v-select
            clearable
            v-if="teachers"
            return-object
            v-model="selectedTeacher"
            :items="teachers"
            item-text="DISPLAY_NAME"
            item-value="id"
            label="Багш сонгоx"
          >
            <template #item="{ item }">
              <v-row>
                <v-col>
                  <span class="text-typo"
                    >{{ item.index }}. {{ item.DISPLAY_NAME }}</span
                  >
                </v-col>
                <v-col class="text-end">
                  <small style="color: #bbb">{{ item.ASSIGNMENT_NAME }} </small>
                </v-col>
              </v-row>
            </template>
          </v-select>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="2" v-if="selectedTeacher && !selectedClassGroup">
          <v-select
            :items="[1]"
            v-model="currentSelectedSemester"
            label="Улирал сонгоx"
          ></v-select>
        </v-col>
      </v-row>
      <template v-if="selectedClassGroup || selectedTeacher">
        <v-card-text v-if="selectedClassGroup && renderComponent">
          <ClassGroupCalendary
            :zClassGroup="selectedClassGroup"
            :onlyAllowedLesson="null"
            :readable="true"
          ></ClassGroupCalendary>
        </v-card-text>

        <v-progress-linear
          v-if="selectedClassGroup && renderComponent == false"
          indeterminate
          color="red"
        ></v-progress-linear>

        <v-card-text v-if="selectedTeacher">
          <v-card class="border-radius-xl">
            <v-card-text>
              <v-row justify="end">
                <v-col>
                  <p class="mb-0 mt-6" style="color: #d81b60">
                    Долоо xоногийн нийт цаг: {{ allLessonTime }}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text
              class="px-2 py-0"
              v-if="selectedTeacher && teacherLessons"
            >
              <v-progress-linear
                v-if="loading"
                color="red"
                height="6"
                indeterminate
              ></v-progress-linear>
              <v-data-table
                v-if="selectedTeacher && renderComponent"
                :headers="headerNames3"
                :items="teacherLessons"
                :items-per-page="-1"
                hide-default-footer
                item-key="uniqueId"
              >
                <template v-slot:item.courseInfo.COURSE_NAME="{ item }">
                  <span>
                    {{ item.courseInfo.COURSE_NAME }} <v-icon>mdi-alerm</v-icon>
                  </span>
                </template>
                <template v-slot:item.esisLessonType="{ item }">
                  <span
                    style="cursor: pointer; color: #d81b60"
                    v-if="item.esisLessonType.esisLessonTypeId == 3"
                    >{{ item.esisLessonType.name }} -
                    {{
                      item["numberSelectedStudents-" + currentSelectedSemester]
                    }}<span v-if="item.selectedGroupName"
                      >, ({{ item.selectedGroupName }})</span
                    >
                  </span>
                  <span
                    class="blue--text"
                    v-else-if="item.esisLessonType.esisLessonTypeId == 2"
                    >{{ item.esisLessonType.name }} -
                    {{
                      item["numberSelectedStudents-" + currentSelectedSemester]
                    }}<span v-if="item.selectedGroupName"
                      >, ({{ item.selectedGroupName }})</span
                    >
                  </span>
                  <span v-else>{{ item.esisLessonType.name }} </span>
                </template>
                <template v-slot:item.classGroups="{ item }">
                  <span
                    v-if="item.classGroups && !item.isCollected"
                    style="font-size: 12pt"
                  >
                    <v-btn
                      style="font-size: 10pt"
                      elevation="0"
                      @click="_printClass(cgroup)"
                      class="border-radius-sm shadow-none py-1 px-1 mx-1 green lighten-3"
                      :height="22"
                      :min-width="0"
                      v-for="(cgroup, cgindex) in item.classGroups"
                      :key="cgroup.classGroupFullName + cgindex"
                      >{{ cgroup.classGroupFullName }}</v-btn
                    >
                  </span>
                  <!-- <span
                  @click="_editClassGroup(item)"
                  v-if="
                    !item.classGroups &&
                    item.esisLessonType.esisLessonTypeId == 3
                  "
                  style="font-size: 12pt; color: #d81b60; cursor: pointer"
                >
                  Бүлэг+
                </span> -->
                </template>

                <template v-slot:item.calDatas="{ item }">
                  <div class="d-flex justify-start">
                    <span
                      style="cursor: pointer; white-space: nowrap"
                      class="hover-dashed"
                      v-for="(calData, calIndex) in _getFormatedCalData(item)"
                      :key="calData + calIndex + item.id"
                      ><span v-if="calIndex > 0">, </span>
                      <span>{{ calData.garagName }}-{{ calData.time }} </span>

                      <!-- { "semester": 1, "calendarVersion": 1, "garag": 1, "garagName": "Да", "calendarEelj": 1, "time": 1 } -->
                    </span>
                  </div>
                </template>
                <!-- <template v-slot:item.calDatas="{ item }">
                <div class="d-flex justify-start">
                  <span
                    style="cursor: pointer; white-space: nowrap"
                    class="hover-dashed"
                    @click="_deleteCalData(item, calData)"
                    v-for="(calData, calIndex) in _getFormatedCalData(item)"
                    :key="calData + calIndex + selectedTab"
                    ><span v-if="calIndex > 0">, </span>
                    <span>{{ calData.garagName }}-{{ calData.time }} </span>
                  </span>
                </div>
              </template> -->
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-card-text>
      </template>
      <template v-else>
        <v-alert
          color="#F8BBD0"
          class="pt-4 mx-8"
          text
          prominent
          icon="mdi-check-circle-outline"
        >
          <div style="color: #d81b60; font-weight: normal">
            Xичээлийн xуваарь xараx БҮЛЭГ эсвэл БАГШАА сонгоорой!
          </div>
        </v-alert>
      </template>
    </v-card>
  </v-app>
</template>

<script>
// const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import ClassGroupCalendary from "@/sms/huvaari/ClassGroupCalendary.vue";
export default {
  data() {
    return {
      renderComponent: true,
      selectedClassGroup: null,
      allClassGroups: null,
      departments: null,
      allWeeks: null,
      currentSelectedSemester: null,
      headerNames3: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          width: "1%",
          value: "index",
        },
        {
          text: "Xичээлийн нэр",
          value: "courseInfo.COURSE_NAME",
          sortable: true,
          width: "30%",
        },
        {
          text: "Төрөл",
          align: "start",
          value: "esisLessonType",
          width: "14%",
        },
        {
          text: "Бүлгүүд",
          align: "start",
          value: "classGroups",
          sortable: true,
          width: "25%",
        },
        {
          text: "Xувиарууд",
          align: "start",
          value: "calDatas",
          sortable: true,
          width: "30%",
        },
        // {
        //   text: "Xуваарь+",
        //   align: "start",
        //   value: "calplus",
        //   width: "5%",
        // },
        // {
        //   text: "Үйлдэл",
        //   align: "start",
        //   value: "actions",
        // },
      ],
      teachers: null,
      teacherLessons: null,
      selectedTeacher: null,
    };
  },
  methods: {
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
    _findClassGroups() {
      this.loading = true;
      this.allClassGroups = [];
      this.userData.school.ref
        .collection("departments-" + this.userData.school.currentYear)
        .orderBy("index", "desc")
        .get()
        .then((docs) => {
          this.departments = [];
          this.classGroups = [];
          var counter = 0;
          this.numberOfAllGroups = 0;
          docs.forEach((doc) => {
            this.countOfPrograms = 0;
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            dep.classGroups = null;
            this.loading = true;
            dep.ref
              .collection("programs")
              .where("deleted", "==", false)
              .orderBy("name", "asc")
              .get()
              .then((docs) => {
                dep.classGroups = [];
                dep.numberOfStudents = 0;
                docs.forEach(async (doc) => {
                  // doc.data() is never undefined for query doc snapshots
                  let prog = doc.data();
                  prog.id = doc.id;
                  prog.ref = doc.ref;
                  // if (
                  //   prog.numberOfStudents != undefined &&
                  //   prog.numberOfStudents != null
                  // )
                  //   dep.numberOfStudents =
                  //     dep.numberOfStudents + prog.numberOfStudents;
                  // prog.index = counter++;
                  // this.numberOfAllGroups++;
                  // prog.loading = false;
                  // console.log(prog);
                  counter++;
                  prog.index = counter;
                  dep.classGroups.push(prog);
                  this.allClassGroups.push(prog);
                });
                this.loading = false;
              });
            this.departments.push(dep);
          });
          this.loading = false;
        });
    },
    _getDay(dayIndex) {
      var dayName = null;
      switch (dayIndex) {
        case 1:
          dayName = "Да";
          break;
        case 2:
          dayName = "Мя";
          break;
        case 3:
          dayName = "Лх";
          break;
        case 4:
          dayName = "Пү";
          break;
        case 5:
          dayName = "Ба";
          break;
        case 6:
          dayName = "Бя";
          break;
        case 7:
          dayName = "Ня";
          break;
      }
      return dayName;
    },
    _getFormatedCalData(lesson) {
      var calDatas = [];
      if (lesson._calDatas && lesson._calDatas.length > 0) {
        for (const xx of lesson._calDatas) {
          var calData = xx.split("-");
          if (
            calData[0] &&
            calData[0] == String(this.currentSelectedSemester)
          ) {
            var calObject = {
              semester: Number(calData[0]),
              calendarVersion: Number(calData[1]),
              garag: Number(calData[2]),
              garagName: this._getDay(Number(calData[2])),
              calendarEelj: Number(calData[3]),
              time: Number(
                calData[4].startsWith("0") ? calData[4].slice(1) : calData[4]
              ),
            };
            calDatas.push(calObject);
          }
        }
        return calDatas;
      } else return null;
    },
  },
  components: {
    ClassGroupCalendary,
  },
  watch: {
    selectedClassGroup(val) {
      if (val) {
        this.selectedTeacher = null;
        this.forceRerender();
      }
    },
    selectedTeacher(val) {
      this.selectedClassGroup = null;
      if (val) console.log(val.ref.path);
      if (val) {
        this.loading = false;
        this.userData.school.ref
          .collection("lessons-" + this.userData.school.currentYear)
          .where("teacherRefs", "array-contains", this.selectedTeacher.ref)
          .orderBy("courseInfo.COURSE_NAME", "asc")
          .get()
          .then(async (docs) => {
            this.teacherLessons = [];
            var counter = 0;
            docs.forEach(async (doc) => {
              counter++;
              let lesson = doc.data();
              lesson.id = doc.id;
              lesson.ref = doc.ref;
              lesson.executions = null;
              lesson.index = counter;
              this.teacherLessons.push(lesson);
            });
            this.loading = false;
            this.forceRerender();
          });
      }
    },
  },
  created() {
    this.currentSelectedSemester = this.$store.state.runningSemester;
    this._findClassGroups();
    this.loading = true;

    this.userData.school.ref
      .collection("teachers")
      .where("role", "==", "teacher")
      .where("deleted", "==", false)
      .orderBy("FIRST_NAME", "asc")
      .onSnapshot((docs) => {
        this.teachers = [];
        var counter = 0;
        docs.forEach(async (doc) => {
          let teacher = doc.data();
          teacher.id = doc.id;
          teacher.ref = doc.ref;
          if (!teacher.moved) {
            counter++;
            teacher.index = counter;
            this.teachers.push(teacher);
          }
        });
        this.loading = false;
      });
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    filteredLessons() {
      var list = [];
      var counter = 0;
      if (this.lessons) {
        for (var ll of this.lessons) {
          counter++;
          ll.index = counter;
          ll.uniqueId = ll.id + counter;
          ll.name3 = counter + ". " + ll.name2;
          list.push(ll);
        }
      }
      return list;
    },
    allLessonTime() {
      if (this.teacherLessons) {
        var summe = 0;
        for (const ll of this.teacherLessons) {
          if (
            ll._calDatas &&
            ll._calDatas.filter((xx) => xx[0] == this.currentSelectedSemester)
          ) {
            summe = summe + ll._calDatas.length;
          }
        }
      }
      return summe;
    },
  },
};
</script>
<style>
[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}
[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: yellow;
  color: black;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}
[data-title2] {
  position: relative;
}
</style>
 